/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    margin: 1px 0 0;
}


.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.accordion__button:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    transform: rotate(45deg);
    position: absolute;
    right: 0;
}

.accordion__button {
    background-color: #00000099;
    color: #fff;
    cursor: pointer;
    padding: 0 15px;
    width: 100%;
    text-align: left;
    border: none; 
    display: flex;
    min-height: 45px;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    transition: all .5s ease-in-out;
    border-radius: 5px;

    &.hideArrow {
        &::after {
            display: none;
        }
    }

    &.firstLevel {
        background-color: #262626;
    }

    &.lastLevel,
    &.secondLevel {
        background-color: #5a5a5a;
    }

    &.secondLevel {
        padding: 0 15px 0 30px;
    }

    &.lastLevel {
        padding: 0 15px 0 45px;
    }    
}

.accordion__button[aria-expanded='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &.firstLevel,
    &.lastLevel,
    &.secondLevel {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.accordion__button:hover {
    background-color: #ddd;
    color: #81818199;

    &.firstLevel {
        background-color: #262626;
        color: #ffffff;
    }

    &.secondLevel,
    &.lastLevel {
        background-color: #5a5a5a;
        color: #ffffff;
    }
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
    transform: rotate(225deg);
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
}

[hidden] {
    display: none;
}

.accordion__panel {
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
