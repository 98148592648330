@import '../assets/styles/vars';
@import '../assets/styles/mixins';
@import '../assets/styles/fonts';
@import '../assets/styles/reset';
@import '../assets/styles/accordion';

.grid {
  margin: -40px 0 0;
  padding: 5px 0 0;
}

.highlightClass {
  background: orange;
  color: #000000;
}

.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: inherit;
  padding: 0 0 18px;
}

.control-dots {
  position: absolute;
  bottom: -10px !important;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 0;

  .dot {
    background: #656565 !important;
  }
}

.carousel .slide iframe {
  width: 100%;
  margin: 0;
}

/* route transition */
.route-enter {
  transform: translateX(100%);
}
.route-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.route-exit {
  transform: translateX(0);
}
.route-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms;
}

/* route-inverted transition */
.route-inverted-enter {
  transform: translateX(-100%);
}
.route-inverted-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}
.route-inverted-exit {
  transform: translateX(0);
}
.route-inverted-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}

/* settings transition */
.settings-enter {
  opacity: 0;
}
.settings-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.settings-exit {
  opacity: 1;
}
.settings-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.settings-enter .settings-box {
  transform: translateX(100%);
}
.settings-enter-active .settings-box {
  transform: translateX(0);
  transition: transform 300ms;
}
.settings-exit .settings-box {
  transform: translateX(0);
}
.settings-exit-active .settings-box {
  transform: translateX(100%);
  transition: transform 300ms;
}

/* image-view-filter transition */
.image-view-filter-enter {
  transform: translateY(100%);
}
.image-view-filter-enter-active {
  transform: translateY(0);
  transition: transform 300ms;
}
.image-view-filter-exit {
  transform: translateY(0);
}
.image-view-filter-exit-active {
  transform: translateY(100%);
  transition: transform 300ms;
}

.App {
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: $screen-xs-max;
  margin: 0 auto;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

#root {
  background: black;
}

.btnLogOut {
  font-size: initial;
}

// .userHistoryContainer,
.configBIContainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 300%;
  color: red;
  font-weight: bold;
  flex-direction: column;
  position: relative;
}

html {
  position: relative;
  overflow-y: scroll;
}

body {
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
}
